import {defineStore} from "pinia"

export const infoStore = defineStore("info", {
    state: () => ({
        plans: [],
        platforms: [],
        time_formats: [],
        first_day_of_the_weeks: null,
        timezones: [],
        wip_integrations: [],
        platform_statuses: [],
    }),

    actions: {
        async load() {
            return useNuxtApp().$api(`/info`, {
                method: 'GET',
            })
                .then((response) => {
                    this.plans = response.data.plans

                    let platforms = response.data.platforms
                    Object.entries(platforms).forEach(([key, platform]) => {
                        platform.to = `/platforms/${key}`
                    });
                    this.platforms = platforms

                    this.time_formats = response.data.time_formats
                    this.first_day_of_the_weeks = response.data.first_day_of_the_weeks
                    this.timezones = response.data.timezones
                    this.wip_integrations = response.data.wip_integrations
                    this.platform_statuses = response.data.platform_statuses
                })
        },

        findPlatformByKey(key) {
            return this.platforms[key]
        }
    }
})