import { default as indexV7weoNT4crMeta } from "/home/forge/thelisting.app/pages/auth/code-flow/[[platform]]/callback/index.vue?macro=true";
import { default as indext6HkjhYX6fMeta } from "/home/forge/thelisting.app/pages/auth/linkedin/callback/index.vue?macro=true";
import { default as _91token_93VhScbYDhpjMeta } from "/home/forge/thelisting.app/pages/auth/reset/password/[token].vue?macro=true";
import { default as indexlHcn55UXttMeta } from "/home/forge/thelisting.app/pages/auth/tiktok/callback/index.vue?macro=true";
import { default as indexkmdbmDDkjxMeta } from "/home/forge/thelisting.app/pages/auth/verify/email/index.vue?macro=true";
import { default as indexkfjIwn9a5MMeta } from "/home/forge/thelisting.app/pages/auth/with-pages/[[platform]]/callback/index.vue?macro=true";
import { default as index8yhvD8yosGMeta } from "/home/forge/thelisting.app/pages/calendar/index.vue?macro=true";
import { default as indexctb71C5nUMMeta } from "/home/forge/thelisting.app/pages/connections/index.vue?macro=true";
import { default as indexe3qQVHTO1XMeta } from "/home/forge/thelisting.app/pages/connections/new/index.vue?macro=true";
import { default as index4ZOOfAF1cxMeta } from "/home/forge/thelisting.app/pages/create/custom/index.vue?macro=true";
import { default as indexSwJphTmBrWMeta } from "/home/forge/thelisting.app/pages/edit/[[id]]/custom/index.vue?macro=true";
import { default as indexLRkzCMHoIrMeta } from "/home/forge/thelisting.app/pages/forgot/password/index.vue?macro=true";
import { default as indexiudIUtLOdfMeta } from "/home/forge/thelisting.app/pages/hashtag-lists/create/index.vue?macro=true";
import { default as _91_91id_93_93g1NcldnRHPMeta } from "/home/forge/thelisting.app/pages/hashtag-lists/edit/[[id]].vue?macro=true";
import { default as indexS3pn8E5tdEMeta } from "/home/forge/thelisting.app/pages/hashtag-lists/index.vue?macro=true";
import { default as indexF3aQWIXWJkMeta } from "/home/forge/thelisting.app/pages/index.vue?macro=true";
import { default as index9RXbT0mIwKMeta } from "/home/forge/thelisting.app/pages/login/index.vue?macro=true";
import { default as indexXhkdmFJvCUMeta } from "/home/forge/thelisting.app/pages/platforms/[[platform]]/index.vue?macro=true";
import { default as indexNN1SDfWCqfMeta } from "/home/forge/thelisting.app/pages/posts/index.vue?macro=true";
import { default as indexgQm4yEY9lsMeta } from "/home/forge/thelisting.app/pages/pricing/index.vue?macro=true";
import { default as indexORlsMjtAfKMeta } from "/home/forge/thelisting.app/pages/privacy-policy/index.vue?macro=true";
import { default as indextBbQ5STn6UMeta } from "/home/forge/thelisting.app/pages/register/index.vue?macro=true";
import { default as indexmrogTESTJQMeta } from "/home/forge/thelisting.app/pages/settings/account/index.vue?macro=true";
import { default as indexbPiZhb66RGMeta } from "/home/forge/thelisting.app/pages/settings/billing/index.vue?macro=true";
import { default as indexXMdlMFEonFMeta } from "/home/forge/thelisting.app/pages/settings/notifications/index.vue?macro=true";
import { default as indexXxLbIqIWRiMeta } from "/home/forge/thelisting.app/pages/settings/security/index.vue?macro=true";
import { default as indexBjT6rbDK8XMeta } from "/home/forge/thelisting.app/pages/settings/workspace/index.vue?macro=true";
import { default as indexzJ4MAM5V2ZMeta } from "/home/forge/thelisting.app/pages/signatures/create/index.vue?macro=true";
import { default as _91_91id_93_9366piVwPm4cMeta } from "/home/forge/thelisting.app/pages/signatures/edit/[[id]].vue?macro=true";
import { default as indexKSzLz7HT47Meta } from "/home/forge/thelisting.app/pages/signatures/index.vue?macro=true";
import { default as indexjXCNXHxAPfMeta } from "/home/forge/thelisting.app/pages/templates/create/index.vue?macro=true";
import { default as _91_91id_93_930jsAcoQSQ3Meta } from "/home/forge/thelisting.app/pages/templates/edit/[[id]].vue?macro=true";
import { default as indexAppfetFSajMeta } from "/home/forge/thelisting.app/pages/templates/index.vue?macro=true";
import { default as indexpcOpWb43iVMeta } from "/home/forge/thelisting.app/pages/terms-of-service/index.vue?macro=true";
import { default as indexDNnsAJE6yGMeta } from "/home/forge/thelisting.app/pages/watermarks/create/index.vue?macro=true";
import { default as _91_91id_93_93QIKmWhhwjAMeta } from "/home/forge/thelisting.app/pages/watermarks/edit/[[id]].vue?macro=true";
import { default as indexfhaPQLpJozMeta } from "/home/forge/thelisting.app/pages/watermarks/index.vue?macro=true";
import { default as indexSOWHVPup1jMeta } from "/home/forge/thelisting.app/pages/workspaces/create/index.vue?macro=true";
import { default as _91_91id_93_93DcXWRc8JTNMeta } from "/home/forge/thelisting.app/pages/workspaces/edit/[[id]].vue?macro=true";
export default [
  {
    name: "auth-code-flow-platform-callback",
    path: "/auth/code-flow/:platform?/callback",
    meta: indexV7weoNT4crMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/auth/code-flow/[[platform]]/callback/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-linkedin-callback",
    path: "/auth/linkedin/callback",
    meta: indext6HkjhYX6fMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/auth/linkedin/callback/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password-token",
    path: "/auth/reset/password/:token()",
    component: () => import("/home/forge/thelisting.app/pages/auth/reset/password/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-tiktok-callback",
    path: "/auth/tiktok/callback",
    meta: indexlHcn55UXttMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/auth/tiktok/callback/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email",
    path: "/auth/verify/email",
    meta: indexkmdbmDDkjxMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/auth/verify/email/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-with-pages-platform-callback",
    path: "/auth/with-pages/:platform?/callback",
    meta: indexkfjIwn9a5MMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/auth/with-pages/[[platform]]/callback/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar",
    meta: index8yhvD8yosGMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "connections",
    path: "/connections",
    meta: indexctb71C5nUMMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/connections/index.vue").then(m => m.default || m)
  },
  {
    name: "connections-new",
    path: "/connections/new",
    meta: indexe3qQVHTO1XMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/connections/new/index.vue").then(m => m.default || m)
  },
  {
    name: "create-custom",
    path: "/create/custom",
    meta: index4ZOOfAF1cxMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/create/custom/index.vue").then(m => m.default || m)
  },
  {
    name: "edit-id-custom",
    path: "/edit/:id?/custom",
    meta: indexSwJphTmBrWMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/edit/[[id]]/custom/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot/password",
    meta: indexLRkzCMHoIrMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/forgot/password/index.vue").then(m => m.default || m)
  },
  {
    name: "hashtag-lists-create",
    path: "/hashtag-lists/create",
    meta: indexiudIUtLOdfMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/hashtag-lists/create/index.vue").then(m => m.default || m)
  },
  {
    name: "hashtag-lists-edit-id",
    path: "/hashtag-lists/edit/:id?",
    meta: _91_91id_93_93g1NcldnRHPMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/hashtag-lists/edit/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "hashtag-lists",
    path: "/hashtag-lists",
    meta: indexS3pn8E5tdEMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/hashtag-lists/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/thelisting.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: index9RXbT0mIwKMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "platforms-platform",
    path: "/platforms/:platform?",
    component: () => import("/home/forge/thelisting.app/pages/platforms/[[platform]]/index.vue").then(m => m.default || m)
  },
  {
    name: "posts",
    path: "/posts",
    meta: indexNN1SDfWCqfMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/home/forge/thelisting.app/pages/pricing/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/forge/thelisting.app/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: indextBbQ5STn6UMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "/settings/account",
    meta: indexmrogTESTJQMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/settings/account/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "/settings/billing",
    meta: indexbPiZhb66RGMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/settings/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "/settings/notifications",
    meta: indexXMdlMFEonFMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/settings/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-security",
    path: "/settings/security",
    meta: indexXxLbIqIWRiMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/settings/security/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "/settings/workspace",
    meta: indexBjT6rbDK8XMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/settings/workspace/index.vue").then(m => m.default || m)
  },
  {
    name: "signatures-create",
    path: "/signatures/create",
    meta: indexzJ4MAM5V2ZMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/signatures/create/index.vue").then(m => m.default || m)
  },
  {
    name: "signatures-edit-id",
    path: "/signatures/edit/:id?",
    meta: _91_91id_93_9366piVwPm4cMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/signatures/edit/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "signatures",
    path: "/signatures",
    meta: indexKSzLz7HT47Meta || {},
    component: () => import("/home/forge/thelisting.app/pages/signatures/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-create",
    path: "/templates/create",
    meta: indexjXCNXHxAPfMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/templates/create/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-edit-id",
    path: "/templates/edit/:id?",
    meta: _91_91id_93_930jsAcoQSQ3Meta || {},
    component: () => import("/home/forge/thelisting.app/pages/templates/edit/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    meta: indexAppfetFSajMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/home/forge/thelisting.app/pages/terms-of-service/index.vue").then(m => m.default || m)
  },
  {
    name: "watermarks-create",
    path: "/watermarks/create",
    meta: indexDNnsAJE6yGMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/watermarks/create/index.vue").then(m => m.default || m)
  },
  {
    name: "watermarks-edit-id",
    path: "/watermarks/edit/:id?",
    meta: _91_91id_93_93QIKmWhhwjAMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/watermarks/edit/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "watermarks",
    path: "/watermarks",
    meta: indexfhaPQLpJozMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/watermarks/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaces-create",
    path: "/workspaces/create",
    meta: indexSOWHVPup1jMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/workspaces/create/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaces-edit-id",
    path: "/workspaces/edit/:id?",
    meta: _91_91id_93_93DcXWRc8JTNMeta || {},
    component: () => import("/home/forge/thelisting.app/pages/workspaces/edit/[[id]].vue").then(m => m.default || m)
  }
]